<template>
	<div>
		<div class="h-100 w-100 d-flex flex-column container-fluid card-panel bg-white shadow-sm shadow-hover">
			<template v-if="isLoading">
				<BaseLoading />
			</template>
			<template v-else>
				<div class="row">
					<div class="col-8">
						<h1 class="h1 lmx-tela-titulo d-block float-left"
							>Finalizar balanço {{ idBalanco }} - {{ descricaoBalanco }}</h1
						>
					</div>
					<div class="col-4">
						<div class="float-right">
							<button class="btn btn-default" data-toggle="modal" @click="voltarParaListagem"> Voltar </button>
							<button class="btn btn-secondary" data-toggle="modal" @click="print"> Imprimir </button>
							<button type="button" class="btn btn-primary rounded" @click="finalizarBalanco">Finalizar</button>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 pl-4">
						<span>{{ descricaoConferidos }} em {{ descricaoSecoes }}</span>
					</div>
				</div>
				<div class="row" v-if="!desconsiderarDiferencas">
					<div class="col-12 pl-4 text-danger">
						<strong
							><span class="text-dark">Depósito: </span>{{ descricaoDivergencias }} entre o balanço e o saldo atual
							dos produtos</strong
						>
					</div>
					<div class="col-12 pl-4 text-danger" v-if="balancoControlaLocalizacao">
						<strong
							><span class="text-dark">WMS: </span>{{ descricaoDivergenciasWMS }} entre o balanço e o saldo nas
							localizações dos produtos</strong
						>
					</div>
				</div>
				<div class="row">
					<div class="col-12 pl-4 pt-2" v-if="produtosComDivergenciaSaldoLote">
						<span style="font-size: 16px; font-weight: bold;">*Esse produto possui divergência entre o saldo dos lotes e o saldo do produto, portanto, será realizado o ajuste automático do saldo.</span>
					</div>
					<div class="col-12 pl-4 pt-2">
						<span style="font-size: 16px;">Movimentações que serão executadas para correção de saldo:</span>
					</div>
				</div>
				<!-- Tabela -->
				<div id="finalizarBalanco" class="row flex-grow-1 mt-3">
					<div class="col-12">
						<div class="table-responsive mb-3">
							<table class="table table-sm table-striped table-hover lmx-tabela-relatorio" cellspacing="1">
								<thead>
									<tr v-if="balancoControlaLocalizacao">
										<th :colspan="returnColspan"></th>
										<th class="text-center align-middle" colspan="2">Depósito</th>
										<th></th>
									</tr>
									<tr>
										<th class="text-center align-middle"></th>
										<th class="text-center align-middle">Código</th>
										<th class="text-center align-middle">Nome</th>
										<th class="text-center align-middle" v-if="balancoComLote">Lote</th>
										<th class="text-center align-middle" v-if="balancoComDataFabricacao"
											>Data de Fabricação</th
										>
										<th class="text-center align-middle" v-if="balancoComDataVencimento">Data de Validade</th>
										<th class="text-center align-middle">Saldo ERP Depósito</th>
										<th class="text-center align-middle" v-if="balancoControlaLocalizacao">Saldo ERP WMS</th>
										<th class="text-center align-middle" v-if="balancoComLote">Saldo Lote</th>
										<th class="text-center align-middle">Qtde Balanço</th>
										<th class="text-center align-middle">Tipo de Ajuste</th>
										<th class="text-center align-middle">Quantidade</th>
										<th class="text-center align-middle" v-if="balancoControlaLocalizacao"
											>Saldo Final WMS</th
										>
									</tr>
								</thead>
								<tbody>
									<template v-for="(produto, index) in produtos">
										<tr :key="index" @click="toggleTabelaSerial(index)" class="cursor-hand">
											<td class="text-center">
												<i :id="'iconeExpandePermissao' + index" v-if="produto.mostrarSerial" class="fas fa-chevron-circle-down"></i>
											</td>
											<td class="text-center">{{ produto.CodigoProduto }}</td>
											<td class="text-center">{{ produto.Nome }}</td>
											<td class="text-center" v-if="balancoComLote"> {{ produto.LoteFormatado }}</td>
											<td class="text-center" v-if="balancoComDataFabricacao">{{
												produto.DataFabricacaoFormatada
											}}</td>
											<td class="text-center" v-if="balancoComDataVencimento">{{
												produto.DataVencimentoFormatada
											}}</td>
											<td class="text-center">{{ formatarValorQtde(produto.Saldo) }}</td>
											<td class="text-center" v-if="balancoControlaLocalizacao">{{
												produto.SaldoLocalizacaoWMS
											}}</td>
											<td class="text-center" v-if="balancoComLote">{{ produto.SaldoDisponivelLote }}</td>
											<td class="text-center">{{ formatarValorQtde(produto.QuantidadeTotalConferencia) }}</td>
											<td class="text-center">{{ produto.TipoAjuste }}</td>
											<td class="text-center" v-if="produto.mostrarSerial">{{ formatarValorQtde(Math.abs(produto.Saldo - produto.QuantidadeTotalConferencia)) }}</td>
											<td class="text-center" :class="{'linha-vermelha': produto.ValorAjusteDivergencia > 0, '': produto.ValorAjusteDivergencia == 0}"
												v-else>{{ produto.ValorAjusteDivergencia == 0 ? formatarValorQtde(produto.ValorAjuste) : "*"+produto.ValorAjusteDivergencia}}</td>
											<td class="text-center" v-if="balancoControlaLocalizacao">{{
												produto.QuantidadeTotalConferencia
											}}</td>
										</tr>
										<tr
											:key="'_' + index"
											v-if="produto.tabelaSerial && produto.Seriais && produto.Seriais.length > 0">

											<td colspan="12">
												<div class="table-responsive mb-3">
                              						<table class="table table-sm table-striped table-hover lmx-tabela-relatorio" cellspacing="1">
														<thead>
															<tr>
																<th class="text-center">{{ legendaControle }}</th>
																<th class="text-center">Saldo ERP Depósito</th>
																<th class="text-center">Qtde Balanço</th>
																<th class="text-center">Tipo de Ajuste</th>
																<th class="text-center">Quantidade</th>
															</tr>
														</thead>
														<tbody>
															<tr v-for="(serial, idx) in produto.Seriais" :key="idx">
																<td class="text-center">{{ serial.Serial }}</td>
																<td class="text-center">{{ formatarValorQtde(serial.Saldo) }}</td>
																<td class="text-center">{{ formatarValorQtde(serial.QuantidadeTotalConferencia) }}</td>
																<td class="text-center">{{ serial.TipoAjuste }}</td>
																<td class="text-center">{{ formatarValorQtde(serial.ValorAjuste) }}</td>
															</tr>
														</tbody>
													</table>
												</div>
											</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-md-4 mb-2">
						<small class="text-muted pl-2">
							Qtde. de Registros:
							<b>{{ produtos ? produtos.length : 0 }}</b>
						</small>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import VueHtmlToPaper from 'vue-html-to-paper';
	import { EventBus } from '@/common/eventBus';
	import swal from '@/common/alerts';
	import loading from '@/common/loading';
	import dateHelper from '@/common/date';

	const options = {
		name: '_blank',
		specs: ['fullscreen=yes', 'titlebar=no', 'scrollbars=yes'],
		timeout: 1000, // default timeout before the print window appears
		autoClose: true, // if false, the window will not close after printing
		windowTitle: window.document.title, // override the window title
	};

	Vue.use(VueHtmlToPaper, options);
	export default {
		name: 'FinalizarBalanco',
		components: {},
		props: {
			balancoSelecionado: {
				type: Object,
				default: () => {
					return { IdBalanco: 0, Descricao: null };
				},
			},
		},

		data() {
			return {
				idBalanco: null,
				descricaoBalanco: null,
				registrosPorPagina: 25,
				produtos: null,
				secoes: null,
				totalConferidos: null,
				totalDivergencias: null,
				totalDivergenciasWMS: null,
				balancoViaUploadArquivo: null,
				desconsiderarDiferencas: null,
				balancoControlaLocalizacao: null,
				ordenacao: {
					campo: null,
					tipo: null,
					enum: { Asc: 1, Desc: 0 },
				},
				paginacao: {
					paginaAtual: 1,
				},
				legendaControle: null,
			};
		},
		mounted() {
			this.idBalanco = this.$route.params.idBalanco;
			this.descricaoBalanco = this.$route.params.descricao;
			this.obterDadosParaFinalizacao(this.idBalanco);
		},

		computed: {
			isLoading() {
				return this.$store.state.uiState.isLoading;
			},
			descricaoConferidos() {
				return (
					this.formatarValorQtde(this.totalConferidos) +
					' ' +
					(this.totalConferidos > 1 ? 'produtos conferidos' : 'produto conferido')
				);
			},
			descricaoDivergencias() {
				return (
					this.formatarValorQtde(this.totalDivergencias) +
					' ' +
					(this.totalDivergencias > 1 ? 'divergências encontradas' : 'divergência encontrada')
				);
			},
			descricaoDivergenciasWMS() {
				return (
					this.totalDivergenciasWMS +
					' ' +
					(this.totalDivergenciasWMS > 1 ? 'divergências encontradas' : 'divergência encontrada')
				);
			},
			descricaoSecoes() {
				return this.secoes + ' ' + (this.secoes > 1 ? 'seções' : 'seção');
			},
			balancoComLote() {
				return this.produtos?.some((s) => s.Lote);
			},
			balancoComSerial() {
				return this.produtos?.some((p) => p.Seriais?.some((s) => s));
			},
			balancoComDataFabricacao() {
				return this.produtos?.some((s) => s.DataFabricacao);
			},
			balancoComDataVencimento() {
				return this.produtos?.some((s) => s.DataVencimento);
			},
			returnColspan() {
				let colspan = 4;
				if (this.balancoComLote) colspan = colspan + 2;
				if (this.balancoComDataFabricacao) colspan = colspan + 1;
				if (this.balancoComDataVencimento) colspan = colspan + 1;
				if (this.balancoControlaLocalizacao) colspan = colspan + 1;
				return colspan;
			},
			produtosComDivergenciaSaldoLote(){
				return this.produtos?.some((s) => s.ValorAjusteDivergencia > 0);
			}
		},
		methods: {
			obterDadosParaFinalizacao(idBalanco) {
				loading.exibir();
				return this.$store
					.dispatch('balanco/obterDadosIniciaisParaFinalizacao', {
						idBalanco: idBalanco,
					})
					.then((jsonResponse) => {
						this.produtos = jsonResponse.ProdutosQuantidades.map((prod) => {
							return {
								...prod,
								DataFabricacaoFormatada: prod.DataFabricacao ? dateHelper.toStringPtBr(prod.DataFabricacao) : '-',
								DataVencimentoFormatada: prod.DataVencimento ? dateHelper.toStringPtBr(prod.DataVencimento) : '-',
								LoteFormatado: prod.Lote && prod.Lote != '' ? prod.Lote : '-',
								SaldoDisponivelLote: prod.Lote && prod.Lote != '' ? prod.SaldoDisponivelLote : '-',
								mostrarSerial: prod.Seriais?.length > 0,
								tabelaSerial: true
							};
						});
						this.secoes = jsonResponse.Secoes;
						this.totalConferidos = jsonResponse.TotalConferidos;
						this.totalDivergencias = jsonResponse.TotalDivergencias;
						this.totalDivergenciasWMS = jsonResponse.TotalDivergenciasWMS;
						this.balancoViaUploadArquivo = jsonResponse.BalancoUploadArquivo;
						this.desconsiderarDiferencas = jsonResponse.DesconsiderarDiferencas;
						this.balancoControlaLocalizacao = jsonResponse.ControlaLocalizacao;
						this.legendaControle = jsonResponse.ParametrosSuprimentos.LegendaControle;
						return Promise.resolve(jsonResponse);
					})
					.catch((erro) => {
						let mensagemErro = '';

						if (erro.response) {
							if (erro.response.status == 400) mensagemErro = erro.data;
							else mensagemErro = erro.message;
						} else mensagemErro = erro;

						swal.exibirMensagemErro(mensagemErro);
						this.voltarParaListagem();
						return Promise.reject(mensagemErro);
					})
					.finally(function () {
						loading.ocultar();
					});
			},

			voltarParaListagem() {
				this.$router.push({ name: 'PainelBalancos' });
			},

			finalizarBalanco() {
				loading.exibir();
				var command = {};
				command.IdBalanco = this.idBalanco;
				command.QuantidadeDivergente =
					this.totalDivergencias + (this.balancoControlaLocalizacao ? this.totalDivergenciasWMS : 0);

				return this.$store
					.dispatch('balanco/finalizarBalanco', command)
					.then((jsonResponse) => {
						if (jsonResponse != '') {
							swal.exibirMensagemSucesso(jsonResponse.Notifications[0], () => {
								this.$router.push({ name: 'PainelBalancos' });
							});
						} else {
							swal.exibirMensagemSucesso(
								'Iniciando o processamento do balanco ' +
									command.IdBalanco +
									' <br>Acompanhe em tempo real o status no painel de balanços.',
								() => {
									this.$router.push({ name: 'PainelBalancos' });
								},
							);
						}

						return Promise.resolve();
					})
					.catch(function (erro) {
						let mensagemErro = '';

						if (erro.response) {
							if (erro.response.status == 400) mensagemErro = erro.data;
							else mensagemErro = erro.message;
						} else mensagemErro = erro;

						swal.exibirMensagemErro(mensagemErro);
						return Promise.reject(mensagemErro);
					})
					.finally(function () {
						loading.ocultar();
					});
			},

			verificarSeExibeBotaoPagina(pagina) {
				return (
					this.paginacao.paginaAtual == pagina ||
					this.paginacao.paginaAtual + 1 == pagina ||
					this.paginacao.paginaAtual - 1 == pagina ||
					this.totalPaginas == pagina ||
					this.totalPaginas - 1 == pagina ||
					1 == pagina ||
					2 == pagina
				);
			},
			print() {
				this.$htmlToPaper('finalizarBalanco');
			},
			formatarValorQtde(value) {
				var self = this;
				return new Intl.NumberFormat('pt-BR', {
					maximumFractionDigits: self.$store.state.balanco.parametroCasasDecimaisQuantidadeProduto,
					roundingMode: 'trunc',
				}).format(parseFloat(value));
			},
			toggleTabelaSerial(index) {
				if(this.produtos[index].mostrarSerial){
					this.produtos[index].tabelaSerial = !this.produtos[index].tabelaSerial;

					const icone = document.getElementById('iconeExpandePermissao' + index);

					if (this.produtos[index].tabelaSerial) {
						icone.classList.remove('fa-chevron-circle-right');
						icone.classList.add('fa-chevron-circle-down');
					} else {
						icone.classList.remove('fa-chevron-circle-down');
						icone.classList.add('fa-chevron-circle-right');
					}
				}
			}
		},
	};
</script>

<style scoped>
	.cursor-hand {
		cursor: pointer;
	}
	.balanco-selecionado {
		background-color: #ffb200 !important;
	}
	.btn {
		height: 34px;
		margin: 4px;
	}
	a.btn {
		line-height: unset;
	}
	.multiselect__tags {
		border-radius: unset;
		height: 31px !important;
	}
	.lmx-tabela-relatorio thead,
	tfoot {
		background-color: #5b2e90;
		color: white;
	}

	.lmx-tabela-relatorio tbody {
		font-size: 90%;
	}

	.lmx-tabela-relatorio-linha-totais {
		color: #ffffff;
		font-weight: bold;
		background-color: #5b2e90;
	}

	.lmx-tela-relatorio-div-nenhum-registro {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lmx-tela-relatorio-div-nenhum-registro i {
		font-size: 3rem;
	}
	.fa-chevron-circle-down {
		color: #5b2e90;
	}
	.fa-chevron-circle-right {
		color: #5b2e90;
	}
	.linha-vermelha {
		color: red;
	}
</style>
